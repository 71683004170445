import apiCall from 'app/libs/utils/api/apiCall';
import { CartonsResponse } from './types';
import { SharedErrors } from 'libs/utils/api/types';

// orders/:order_number/purchased_shipments/
export const cartonsScope = (orderNumber, path?) => `/orders/${orderNumber}/cartons${path || ''}`;

// orders/:order_number/purchased_shipments
export const getCartons = orderNumber => {
  const url = cartonsScope(orderNumber, '');
  return apiCall.get<CartonsResponse, SharedErrors>({ url });
};
