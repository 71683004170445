import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import Footer from 'styleguide/components/Footer/Footer';
import TitleContainer from '../TitleContainer/TitleContainer';
import { Status } from 'libs/utils/api/types';
import Span from 'styleguide/components/Span/Span';
import CartonItem from './CartonItem';
import { useParams } from 'react-router';
import { getCartons } from 'api/cartons';
import { Carton } from 'api/orders/types';

const TrackingPage = () => {
  const [cartons, setCartons] = React.useState<Carton[]>();
  const { orderNumber } = useParams<{ orderNumber: string }>();

  const getCartonsHandler = () => {
    getCartons(orderNumber).then(res => {
      if (res.status === Status.Ok) {
        setCartons(res.payload.cartons);
      }
    });
  };

  React.useEffect(() => {
    getCartonsHandler();
  }, []);

  return (
    <>
      <TitleContainer title="Tracking" />
      <Grid.Container>
        <Grid>
          <Grid.Row>
            <Span className="!text-2xl !p-5">Order # {orderNumber}</Span>
          </Grid.Row>
          {cartons &&
            cartons.map((elem, i) => <CartonItem key={elem.id.toString()} carton={elem} index={i} />)}
        </Grid>
      </Grid.Container>
      <Footer />
    </>
  );
};

export default TrackingPage;
